"use client";
import { config } from '@/config';
import { notification } from 'antd';
import axios from 'axios';
import React, { useState } from 'react';

const ContactForm = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: '',
    });
    const [loading, setLoading] = useState(false);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        if (!formData.name || !formData.email || !formData.message) {
            notification.error({
                message: 'Submission Failed',
                description: 'Please fill in all fields before submitting the form.',
            });
            return;
        }

        setLoading(true);

        try {
            const response = await axios.post(`${config.NEXT_PUBLIC_API}/api/v1/contact`, formData);
            if (response.data.success) {
                notification.success({
                    message: 'Feedback Sent',
                    description: 'Your message has been sent successfully.',
                });

                setFormData({
                    name: '',
                    email: '',
                    message: '',
                });
            } else {
                throw new Error('Failed to send feedback');
            }
        } catch (error: any) {
            notification.error({
                message: 'Submission Failed',
                description: error.response?.data?.message || 'There was an issue submitting your feedback. Please try again later.',
            });
        } finally {
            setLoading(false);
        }
    };


    return (
        <form onSubmit={handleSubmit}>
            <div className="relative mb-4">
                <label htmlFor="name" className="leading-7 text-sm text-gray-600">
                    Name
                </label>
                <input
                    type="text"
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                />
            </div>
            <div className="relative mb-4">
                <label htmlFor="email" className="leading-7 text-sm text-gray-600">
                    Email
                </label>
                <input
                    type="email"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                />
            </div>
            <div className="relative mb-4">
                <label htmlFor="message" className="leading-7 text-sm text-gray-600">
                    Message
                </label>
                <textarea
                    id="message"
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 h-32 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"
                ></textarea>
            </div>
            <button
                type="submit"
                className="text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded text-lg"
                disabled={loading}
            >
                {loading ? "Sending..." : "Send Feedback"}
            </button>
        </form>
    );
};

export default ContactForm;
