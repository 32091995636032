"use client";
import React from 'react';
import { motion } from 'framer-motion';
import Link from 'next/link'; // Import Link for handling navigation in Next.js

interface ThemeButtonProps {
    title: string;
    icon?: React.ReactNode;
    Ipos?: "left" | "right";
    className?: string;
    link?: boolean;
    path?: string; // Path for link navigation
}

const ThemeButton = ({ title, icon, Ipos = "right", className, link, path }: ThemeButtonProps) => {
    // Conditionally render a Link component if 'link' is true, otherwise render a button
    const buttonContent = (
        <motion.button
            className={`inline-flex items-center text-gray-50 bg-indigo-900 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-800 rounded text-lg justify-center ${className}`}
            whileHover={{ scale: 1.1 }}
            transition={{ type: "spring", stiffness: 300 }}
        >
            {Ipos === "left" && icon && <span className="mr-2">{icon}</span>}
            {title}
            {Ipos === "right" && icon && <span className="ml-2">{icon}</span>}
        </motion.button>
    );

    if (link && path) {
        return (
            <Link href={path} passHref>
                {buttonContent}
            </Link>
        );
    }

    return buttonContent;
};

export default ThemeButton;
